.Ocr-footer {
  position: absolute;
  bottom: 0px;
  text-align: center;
  width: 100%;
}

.Ocr-indicator {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
  background-color: #CCC;
  color: #fff;
  border-radius: 4px 4px 0 0;
}

.Ocr-button {
  width: 24px;
  height: 24px;
  padding: 0;
  text-align: center;
  border: none;
  border-radius: 50%;
  outline: none;
  background: none;
  color: #fff;
  font-size: 0.75rem;
  cursor: pointer;
  vertical-align: middle;
  margin: 0 4px;
}

.Ocr-buttonIcon {
  display: block;
  fill: currentColor;
  height: 100%;
}

.Ocr-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.Ocr-zoom {
  width: 100%;
  height: 100%;
  display: block;
}

.Ocr-image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.Ocr-zoomLabel {
  display: inline-block;
  width: 40px;
  vertical-align: middle;
}

