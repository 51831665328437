.comment-input {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 240px);
  z-index: 600;
  margin-left: -30px;
  margin-bottom: 0px;
  border: solid;
  border-color: #c0c0c0;
}

/* ちょっと一旦苦肉の策、あとでちゃんとデザイン考える */
.comment-margin-open {
  height: 160px;
}

.comment-margin-close {
  height: 40px;
}

.summary {
  background-color: #fff7d6 !important;
}

.htDimmed {
  background-color: #dcdcdc !important;
  color: #000000 !important;
  font-weight: bold !important;
}

.htDimmedHead {
  background-color: #085995 !important;
  color: white !important;
  font-weight: bold !important;
}

.htSelected {
  background-color: #fff0f5 !important;
}

.chart_custom_tooltip {
  padding: 8px;
  font-size: 12px;
  color: #555;
}
.chart_custom_tooltip .font-bold {
  font-weight: bold;
}
