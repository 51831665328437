/* react-datasheet-grid用 
  仕様情報はここを参考に
  https://react-datasheet-grid.netlify.app/docs/styling/
*/

.rdg-header-title {
  --dsg-header-text-color: #000000;
}

.rdg-border-color {
  --dsg-border-color: #555555;
}

.rdg-disable-bgcolor {
  --dsg-cell-disabled-background-color: #e0f8ff;
}
